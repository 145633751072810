body {
  padding: 0;
  margin: 0;
}

.Toastify__toast {
  border-radius: 12px !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #cacaca;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.apexcharts-svg {
  background: none !important;
}

.dropdown-content {
  z-index: 1;
}
